<template>
  <div class="up-customers-wrapper">
    <header class="up-main-header">
      <h1 class="up-main-title">Мои клиенты</h1>
      <el-input
        v-model="searchValue"
        prefix-icon="el-icon-search"
        placeholder="Поиск по клиентам"
        @change="filterHandler"
      />
    </header>
    <template v-if="customers.length > 0">
      <AppSort
        :sort-params="sortParams"
        :sort-by="sortBy"
        :sort-order="sortOrder"
        @sort="sortHandler($event, 'fetchCustomers')"
      />
      <AppBlockAsTable class="up-customers-wrapper__customers up-customers">
        <template slot="header">
          <p
            class="up-customers__name"
            :class="activeSortClass('name')"
            @click="sortHandler('name', 'fetchCustomers')"
          >
            ФИО <i class="el-icon-top" /> <i class="el-icon-bottom" />
          </p>
          <p
            class="up-customers__email"
            :class="activeSortClass('email')"
            @click="sortHandler('email', 'fetchCustomers')"
          >
            Email <i class="el-icon-top" /> <i class="el-icon-bottom" />
          </p>
          <p
            class="up-customers__phone"
            :class="activeSortClass('phone')"
            @click="sortHandler('phone', 'fetchCustomers')"
          >
            Телефон <i class="el-icon-top" /> <i class="el-icon-bottom" />
          </p>
          <p class="up-customers__actions"></p>
        </template>
        <div v-for="c in customersInPage" :key="c.id" class="up-table__item">
          <p class="up-customers__name">
            <span class="is-pk-hidden">ФИО:</span>
            {{ c.blocked ? "[блок]" : "" }} {{ c.name }}
          </p>
          <p class="up-customers__email">
            <span class="is-pk-hidden">Email:</span> {{ c.email }}
          </p>
          <p class="up-customers__phone">
            <span class="is-pk-hidden">Телефон:</span> {{ c.phone }}
          </p>
          <p class="up-customers__actions">
            <el-dropdown @command="openModal" trigger="click">
              <i class="el-icon-more" />
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="c">
                  <template v-if="c.blocked">
                    <i class="el-icon-lock" /> разблокировать
                  </template>
                  <template v-else>
                    <i class="el-icon-unlock" /> заблокировать
                  </template>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </p>
        </div>
      </AppBlockAsTable>
    </template>
    <p v-else-if="!loading" class="up-empty-list-text">Список пуст</p>
    <footer class="up-customers-wrapper__footer">
      <router-link
        :to="`${$route.path}/new`"
        class="el-button el-button--primary"
      >
        <i class="el-icon-user el-icon--left" /> Новый клиент
      </router-link>
      <el-pagination
        v-if="customers.length > perPage"
        background
        layout="prev, pager, next"
        :total="customers.length"
        :current-page.sync="currentPage"
        :page-size="perPage"
      />
    </footer>
    <AppSimpleModal
      :visible.sync="modalVisible"
      :title="`${blockedText} пользователя`"
      :action-text="blockedText"
      @action="blockHandler"
      @close="modalVisible = false"
    >
      Вы хотите {{ blockedText }} клиента. <br /><br /><small>{{
        blockedDescription
      }}</small>
    </AppSimpleModal>
  </div>
</template>

<script>
import AppBlockAsTable from "@/components/AppBlockAsTable";
import AppSimpleModal from "@/components/AppSimpleModal";
import AppSort from "@/components/AppSort";

import sortMixin from "@/mixins/sort.mixin";

import { mapActions } from "vuex";

export default {
  name: "ManagerClients",
  mixins: [sortMixin],
  components: { AppBlockAsTable, AppSimpleModal, AppSort },
  data: () => ({
    loading: true,
    searchValue: "",
    customers: [],
    currentPage: 1,
    perPage: 15,
    modalVisible: false,
    currentCustomer: {},
    blockedText: "",
    blockedDescription: "",
    sortParams: [
      {
        text: "Имени",
        key: "name",
      },
      {
        text: "Email",
        key: "email",
      },
      {
        text: "Телефону",
        key: "phone",
      },
    ],
  }),
  computed: {
    customersInPage() {
      const startNumber = (this.currentPage - 1) * this.perPage;

      return [...this.customers].slice(startNumber, startNumber + this.perPage);
    },
  },
  methods: {
    ...mapActions(["setNavigationText"]),
    async fetchCustomers() {
      this.$nprogress.start();

      try {
        const { data: customers } = await this.$axios.get(
          "/manager/customers",
          {
            params: {
              sortBy: this.sortBy,
              sortOrder: this.sortOrder,
              q: this.searchValue,
            },
          }
        );

        this.customers = customers;
      } catch (e) {
        //
      } finally {
        this.loading = false;
        this.$nprogress.done();
      }
    },
    filterHandler() {
      this.currentPage = 1;
      this.fetchCustomers();
    },
    async blockHandler() {
      try {
        const { data: res } = await this.$axios.post(
          `/manager/customer/${this.currentCustomer.id}/access`,
          {
            blocked: !this.currentCustomer.blocked,
          }
        );

        this.modalVisible = false;

        const customer = this.customers.find(
          (c) => c.id === this.currentCustomer.id
        );
        const text = res.blocked ? "заблокирован" : "разблокирован";

        customer.blocked = res.blocked;

        this.$message({
          message: `Поздравляем! Пользователь успешно ${text}.`,
          type: "success",
        });
      } catch (e) {
        this.$message({
          message: `Произошла ошибка при попытки ${this.blockedText} пользователя`,
          type: "error",
        });
      }
    },
    openModal(customer) {
      this.blockedText = this.currentCustomer.blocked
        ? "Разблокировать"
        : "Заблокировать";

      this.blockedDescription = this.currentCustomer.blocked
        ? "Заявки клиента будут открыты. Все учебные процессы по заявкам будут возобновлены."
        : "Заявки клиента будут закрыты. Все учебные процессы по заявкам будут приостановлены.";

      this.currentCustomer = customer;
      this.modalVisible = true;
    },
  },
  created() {
    this.setNavigationText("Страница просмотра клиентов");

    this.fetchCustomers();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/pages/manager/customers/index.scss";
</style>